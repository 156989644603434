<template>
  <div class="cat-wrapper">
    <div class="cat-scroll">
      <Flicking
        :options="{ moveType: 'freeScroll', bound: true }"
        ref="flicking"
        v-if="categories.length > 0"
      >
        <div
          class="cat-menu-item-mob"
          @click="goTo(cat.slug)"
          v-for="(cat, index) in categories"
          :class="{ blur: isHovered && isHovered !== 'item' + index }"
        >
          <div
            class="icon-wrapper"
            @mouseover="isHovered = 'item' + index"
            @mouseout="isHovered = null"
          >
            <div class="circle-icon" :style="ballStyle(cat.color)">
              <div class="circle-icon-gradient">
                <div
                  class="icon icon-shdow"
                  :class="'salex sx-' + cat.icon"
                ></div>
                <div class="cat-title hide-on-mobile">{{ cat[labels[lang]] }}</div>
              </div>
            </div>
          </div>
        </div>
      </Flicking>
    </div>
    <div class="control-buttons">
        <button class="control-back" @click="goBack()">
          <b-icon icon="angle-left" />
        </button>
        <button class="control-next" @click="goNext()">
          <b-icon icon="angle-right" />
        </button>
      </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import translationHelper from "@/mixins/translationHelper";
import { Flicking } from "@egjs/vue-flicking";
// import { Fade } from "@egjs/flicking-plugins";
export default {
  name: "CategoriesMenu",
  data() {
    return {
      isHovered: null,
      coverStyle: {
        backgroundColor: "#e30600",
        position: "absolute",
        top: "-50px",
        left: "-50px",
        width: "100%",
        height: "100%",
        zIndex: "100",
        borderRadius: "50%",
      },
      didDestroy: false,
      // plugins: [new Fade()],
    };
  },
  components: {
    Flicking: Flicking,
  },
  mixins: [translationHelper],
  mounted() {
    // this.$refs.flicking.moveTo(3);
    setTimeout(() => {
      console.log("GO NEXT");
      if (!this.didDestroy){
        this.goNext();
      }else{
        console.log("DID DESTROY");
      }
    }, 5000);
  },
  beforeDestroy() {
    this.didDestroy = true;
    // this.$refs.flicking.destroy();
  },
  methods: {
    goTo(slug) {
      this.$router.push("/category/" + slug);
      // this.didClick(col);
      // setTimeout(() => {
      //   this.$router.push("/adverts-list");
      // }, 500);
    },
    ballStyle(color) {
      let style = {};

      //style.backgroundColor = color;
      if (this.isMobile) {
        style = `background-color: ${color};`;
      } else {
        style = `filter: drop-shadow(5px 5px 4px ${color}55) drop-shadow(0px 5px 2px #00000036); background-color: ${color};`;
      }
      return style;
    },
    isHover(index) {
      this.isHovered = index;
    },
    didClick(col) {
      console.log("CLICKED!");
      this.coverStyle.backgroundColor = col;
      var e = window.event;
      const x = e.clientX;
      const y = e.clientY;

      this.coverStyle.display = "block";
      this.coverStyle.top = `${y - 100}px`;
      this.coverStyle.left = `${x - 100}px`;
      this.coverStyle.width = 0;
      this.coverStyle.height = 0;
      this.coverStyle.borderRadius = "0%";

      setTimeout(() => {
        this.coverStyle.top = "0";
        this.coverStyle.left = "0";
        this.coverStyle.width = "100%";
        this.coverStyle.height = "100%";
      }, 0);
    },
    goBack() {
      let step = 3;
      if (this.isMobile) {
        step = 1;
      }else{
        step = 3;
      }
      const currentPanelIndex = this.$refs.flicking.index;
      if (currentPanelIndex == 0) {
        this.$refs.flicking.moveTo(this.countItems - step);
      } else {
        this.$refs.flicking.moveTo(currentPanelIndex - step);
      }
      // $refs.flicking.prev();
    },
    goNext() {
      let step = 3;
      if (this.isMobile) {
        step = 1;
      }else{
        step = 3;
      }
      const currentPanelIndex = this.$refs.flicking.index;
      if (currentPanelIndex == this.countItems - step) {
        this.$refs.flicking.moveTo(0);
      } else {
        this.$refs.flicking.moveTo(currentPanelIndex + step);
      }
      //$refs.flicking.next();
    },
  },
  computed: {
    ...mapState({
      lang: (state) => state.lang,
      categories: (state) => state.categories,
      isMobile: (state) => state.isMobile,
    }),
    countItems() {
      return this.categories.length;
    },
    //...mapState(["user"]),
    // ballStyle(color) {
    //   return {
    //     backgroundColor: this.color,
    //     filter: "drop-shadow(5px 5px 4px #"+color+"36)",
    //   };
    // },
  },
};
</script>
<style scoped>

.cat-wrapper {
  /* max-width: 1200px; */
  overflow: hidden;
  position: relative;
}
.cat-scroll {
  /* background-color: #f5f5f5; */
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  overflow: hidden;
}
/* .cat-scroll:after {
  z-index: 3;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    #f5f5f5 20px,
    transparent 100px,
    transparent calc(100% - 100px),
    #f5f5f5
  );
  pointer-events: none;
  height: 200px;
  border-radius: 10px;
} */

.cat-menu {
  display: grid;
  /* grid-template-columns: repeat(6, 1fr); */
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 5px;
}

.cat-menu-item-mob {
  width: 20%;
}
.cat-menu-item {
  transition: all 0.4s ease-in-out;
  /* grid-area: a;
  align-self: center;
  justify-self: center; */
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}

.icon-wrapper {
  position: relative;
  padding-bottom: 100px;
}
.circle-icon {
  left: 0;
  right: 0;

  margin-left: auto;
  margin-right: auto;
  position: absolute;
  width: 90%;
  height: 100px;
  background-color: #000000;
  border-radius: 10px;
  /* filter: drop-shadow(5px 5px 4px #00000036); */
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

/* .circle-icon:hover {
  width: 90%;
  height: 120px;
  transform: translate(0px, -10px);
   transform: translate(-10px, -10px); 
} */
.circle-icon .icon {
  color: #ffffffec;
  font-size: 2.5rem;
  transition: all 0.1s ease-out;
}
.circle-icon:hover .icon {
  font-size: 3rem;
  color: #ffffffec;
}

.icon-shdow {
  filter: drop-shadow(5px 5px 4px #00000036);
}
.circle-icon-gradient {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.09987745098039214) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  /* text-shadow: 2px 2px #00000063; */
}

.cat-title {
  font-size: 1.2rem;
  /* font-weight: 500; */
  text-align: left;
  color: #fff;
  line-height: 1;
  width: 50%;
  justify-self: center;
}

/* .blur{
  filter: opacity(70%);
} */

.blur .icon-wrapper {
  /* filter: opacity(70%); */
  /* width: 90px;
  height: 90px;
  transform: translate(0px, 5px); */
  /* transform: translate(-10px, -10px); */
}

.on-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  z-index: 100;
  transition: all 0.2s ease-in-out;
  display: none;
}

.pulse {
  /* animation: pulse-animation 1s;
  animation-delay: 0.2s; */
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px #ffffff;
    /* box-shadow: 0 0 0 0px #7cbc4b7e; */
  }
  100% {
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }
}

.flicking-viewport {
  overflow: visible !important;
}


.control-back,
.control-next {
  all: unset;
  display: flex;
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* background-color: #ffffff; */
  color: rgb(145, 145, 145);
  border: none;
  padding: 15px 15px;
  font-size: 2rem;
  line-height: 1;
  cursor: pointer;
  transition: background-color 0.3s ease;
  justify-content: center;
  align-content: center;
  z-index: 4;
  transform: translateY(-60%);
  mix-blend-mode: difference;
}
.control-back {
  position: absolute;
  top: 45%;
  left: -10px;
  border-radius: 0 50% 50% 0;
  /* box-shadow: inset -3px 0px 6px 0px rgb(193 193 193 / 40%);
  border: 1px solid #ffffff; */
}
.control-next {
  position: absolute;
  top: 45%;
  right: -10px;
  border-radius: 50% 0 0 50%;
  /* box-shadow: inset 3px 0px 6px 0px rgb(193 193 193 / 40%);
  border: 1px solid #ffffff; */
}
.control-back:hover,
.control-next:hover {
  /* background-color: #f1f1f1; */
}



@media screen and (max-width: 1400px) {
  .cat-menu-item-mob {
    width: 30%;
    margin-right: 0;
  }
}

@media screen and (max-width: 768px) {
  .cat-menu-item-mob {
    width: 35%;
    margin-right: 0;
  }
  .control-back,
  .control-next {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 412px) {
  .cat-menu-item-mob {
    width: 40%;
    margin-right: 0;
  }
  .control-back,
  .control-next {
    font-size: 1.5rem;
  }
}

/* @media screen and (min-width: 2000px) {
  .cat-menu-item-mob {
    width: 10%;
    margin-right: 0;
  }
} */
</style>
